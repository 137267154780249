import { Link as GatsbyLink } from 'gatsby'
import React, { FC } from 'react'
import {
  AppBar,
  Toolbar,
  Typography,
  Link,
  Button,
  Box,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useDispatch } from 'react-redux'
import { toggleTheme } from '../redux/appModule'
import Logo from '../images/logo.png'
import LogoSvg from '../images/logo.svg'
import langs, { lan, __ } from '../langs'

const useStyles = makeStyles({
  toolbar: {},
  title: {
    flexGrow: 1,
  },
  link: {
    textDecoration: 'none',
  },
  section: {
    maxWidth: '1010px',
    width: '100%',
    padding: '0 15px',
    justifyContent: 'start',
  },
  sectionBg: {
    background: '#f4f4f4',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
})

export interface HeaderProps {
  siteTitle?: string
  addBg?: boolean
}

const logoStr = (color: string) => {
  return `<svg width="253" height="72" viewBox="0 0 253 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M78.4005 52.1338V59.8556H80.3644V57.592H81.6273C83.345 57.592 84.5276 56.5164 84.5276 54.8682C84.5276 53.2094 83.4039 52.1338 81.745 52.1338H78.4005ZM80.3644 53.6375H81.2259C82.05 53.6375 82.5423 54.0709 82.5423 54.8736C82.5423 55.6816 82.05 56.1151 81.2152 56.1151H80.3644V53.6375Z" fill="${color}"/>
<path d="M90.844 55.0341H90.8868L92.1871 59.8556H94.0386L96.056 52.1338H94.0279L92.9845 57.3512H92.9416L91.652 52.1338H90.0788L88.7891 57.3512H88.7463L87.7028 52.1338H85.6747L87.6921 59.8556H89.5436L90.844 55.0341Z" fill="${color}"/>
<path d="M101.966 59.8556H104.053L101.457 52.1338H99.0546L96.4593 59.8556H98.4607L98.953 58.1592H101.479L101.966 59.8556ZM100.194 53.7712H100.237L101.093 56.7465H99.3329L100.194 53.7712Z" fill="${color}"/>
<path d="M113.828 59.8556H115.861L114.379 58.3198C114.791 57.79 115.043 56.998 115.043 56.1793V55.5425H113.517V56.0455C113.517 56.5539 113.443 56.9285 113.293 57.1907L112.057 55.8582C113.068 55.2963 113.571 54.6274 113.571 53.8355C113.571 52.7759 112.651 52.0482 111.313 52.0482C109.98 52.0482 109.001 52.8241 109.001 53.8836C109.001 54.4508 109.258 54.9271 109.959 55.6388C109.948 55.6388 109.627 55.8208 109.584 55.8422C108.621 56.3826 108.198 56.9873 108.198 57.8542C108.198 59.1813 109.253 60 110.949 60C111.896 60 112.763 59.7378 113.293 59.2883L113.828 59.8556ZM111.227 55.0288C110.74 54.5686 110.569 54.301 110.569 53.9799C110.569 53.5518 110.885 53.2361 111.323 53.2361C111.757 53.2361 112.062 53.5465 112.062 53.9799C112.062 54.4027 111.805 54.7238 111.227 55.0288ZM110.596 56.6823C110.638 56.6555 110.729 56.6074 110.788 56.586L112.34 58.2502C112.105 58.5231 111.634 58.689 111.147 58.689C110.419 58.689 109.927 58.293 109.927 57.6937C109.927 57.2816 110.119 56.9873 110.596 56.6823Z" fill="${color}"/>
<path d="M119.75 57.5813C119.777 59.101 121.05 59.9893 122.977 59.9893C125.016 59.9893 126.23 59.0047 126.23 57.4529C126.23 56.2381 125.481 55.5692 123.833 55.2535L123.041 55.1037C122.201 54.9432 121.848 54.7184 121.848 54.3117C121.848 53.8569 122.27 53.5358 122.982 53.5358C123.694 53.5358 124.218 53.8676 124.25 54.4241H126.075C126.054 52.9525 124.909 52 122.966 52C121.19 52 119.9 52.9579 119.9 54.4455C119.9 55.6228 120.697 56.3773 122.238 56.6662L123.03 56.8161C123.945 56.9927 124.277 57.2121 124.277 57.6402C124.277 58.1111 123.78 58.4535 123.014 58.4535C122.26 58.4535 121.666 58.1325 121.575 57.5813H119.75Z" fill="${color}"/>
<path d="M127.907 52.1338V59.8556H129.871V57.592H131.134C132.852 57.592 134.034 56.5164 134.034 54.8682C134.034 53.2094 132.911 52.1338 131.252 52.1338H127.907ZM129.871 53.6375H130.733C131.557 53.6375 132.049 54.0709 132.049 54.8736C132.049 55.6816 131.557 56.1151 130.722 56.1151H129.871V53.6375Z" fill="${color}"/>
<path d="M139.96 59.8556H142.047L139.452 52.1338H137.049L134.454 59.8556H136.455L136.947 58.1592H139.473L139.96 59.8556ZM138.189 53.7712H138.232L139.088 56.7465H137.327L138.189 53.7712Z" fill="${color}"/>
<path d="M148.419 59.8556V56.9766H151.41V55.4729H148.419V53.7177H151.71V52.1338H146.455V59.8556H148.419Z" fill="${color}"/>
<path d="M156.945 52C154.607 52 153.13 53.5358 153.13 55.9973C153.13 58.4589 154.601 59.9893 156.945 59.9893C159.284 59.9893 160.755 58.4589 160.755 55.9973C160.755 53.5358 159.284 52 156.945 52ZM156.945 53.5947C158.042 53.5947 158.759 54.5258 158.759 55.9973C158.759 57.4636 158.042 58.3947 156.945 58.3947C155.843 58.3947 155.131 57.4636 155.131 55.9973C155.131 54.5258 155.848 53.5947 156.945 53.5947Z" fill="${color}"/>
<path d="M164.444 57.1532H165.445L166.772 59.8556H168.977L167.43 56.8642C168.276 56.495 168.752 55.6281 168.752 54.6809C168.752 53.0863 167.714 52.1338 165.787 52.1338H162.48V59.8556H164.444V57.1532ZM164.444 53.6161H165.589C166.29 53.6161 166.745 54.0602 166.745 54.7184C166.745 55.3873 166.317 55.8101 165.595 55.8101H164.444V53.6161Z" fill="${color}"/>
<path d="M178.693 58.2716H175.364V56.6984H178.495V55.2535H175.364V53.7177H178.693V52.1338H173.401V59.8556H178.693V58.2716Z" fill="${color}"/>
<path d="M182.908 57.5278V55.901H179.919V57.5278H182.908Z" fill="${color}"/>
<path d="M187.91 59.9893C189.9 59.9893 191.324 58.78 191.415 56.9445H189.526C189.403 57.8489 188.776 58.4161 187.915 58.4161C186.834 58.4161 186.16 57.501 186.16 55.9866C186.16 54.4883 186.839 53.5733 187.91 53.5733C188.771 53.5733 189.419 54.1726 189.515 55.0823H191.404C191.345 53.2575 189.863 52 187.91 52C185.609 52 184.158 53.4716 184.158 55.992C184.158 58.5178 185.598 59.9893 187.91 59.9893Z" fill="${color}"/>
<path d="M196.704 52C194.365 52 192.888 53.5358 192.888 55.9973C192.888 58.4589 194.36 59.9893 196.704 59.9893C199.042 59.9893 200.514 58.4589 200.514 55.9973C200.514 53.5358 199.042 52 196.704 52ZM196.704 53.5947C197.8 53.5947 198.518 54.5258 198.518 55.9973C198.518 57.4636 197.8 58.3947 196.704 58.3947C195.601 58.3947 194.889 57.4636 194.889 55.9973C194.889 54.5258 195.607 53.5947 196.704 53.5947Z" fill="${color}"/>
<path d="M210.715 59.8556V52.1338H208.446L206.498 57.18H206.455L204.507 52.1338H202.239V59.8556H203.967V55.0341H204.01L205.915 59.8556H207.039L208.949 55.0341H208.986V59.8556H210.715Z" fill="${color}"/>
<path d="M221.125 59.8556V52.1338H218.856L216.908 57.18H216.865L214.917 52.1338H212.649V59.8556H214.377V55.0341H214.42L216.325 59.8556H217.449L219.359 55.0341H219.396V59.8556H221.125Z" fill="${color}"/>
<path d="M228.351 58.2716H225.022V56.6984H228.153V55.2535H225.022V53.7177H228.351V52.1338H223.059V59.8556H228.351V58.2716Z" fill="${color}"/>
<path d="M232.19 57.1532H233.19L234.517 59.8556H236.722L235.176 56.8642C236.021 56.495 236.497 55.6281 236.497 54.6809C236.497 53.0863 235.459 52.1338 233.533 52.1338H230.226V59.8556H232.19V57.1532ZM232.19 53.6161H233.335C234.036 53.6161 234.491 54.0602 234.491 54.7184C234.491 55.3873 234.063 55.8101 233.34 55.8101H232.19V53.6161Z" fill="${color}"/>
<path d="M241.786 59.9893C243.777 59.9893 245.2 58.78 245.291 56.9445H243.402C243.279 57.8489 242.653 58.4161 241.792 58.4161C240.711 58.4161 240.036 57.501 240.036 55.9866C240.036 54.4883 240.716 53.5733 241.786 53.5733C242.648 53.5733 243.295 54.1726 243.392 55.0823H245.281C245.222 53.2575 243.739 52 241.786 52C239.485 52 238.035 53.4716 238.035 55.992C238.035 58.5178 239.475 59.9893 241.786 59.9893Z" fill="${color}"/>
<path d="M252.271 58.2716H248.943V56.6984H252.073V55.2535H248.943V53.7177H252.271V52.1338H246.979V59.8556H252.271V58.2716Z" fill="${color}"/>
<path d="M90.7655 39.9227C96.7254 39.9227 100.421 36.846 100.421 31.931C100.421 28.2351 97.6349 25.4679 93.8422 25.1777V25.0229C96.8028 24.5778 99.0862 22.0235 99.0862 18.9662C99.0862 14.6897 95.7966 12 90.5526 12H78.4005V39.9227H90.7655ZM84.2444 16.3539H89.0433C91.7717 16.3539 93.3391 17.631 93.3391 19.8756C93.3391 22.2364 91.5588 23.6103 88.4434 23.6103H84.2444V16.3539ZM84.2444 35.5689V27.461H89.14C92.5844 27.461 94.4614 28.8543 94.4614 31.4666C94.4614 34.1369 92.6425 35.5689 89.2755 35.5689H84.2444Z" fill="${color}"/>
<path d="M103.401 39.9227H109.052V12H103.401V39.9227Z" fill="${color}"/>
<path d="M115.708 17.8051C117.353 17.8051 118.649 16.4893 118.649 14.9026C118.649 13.2965 117.353 12 115.708 12C114.063 12 112.767 13.2965 112.767 14.9026C112.767 16.4893 114.063 17.8051 115.708 17.8051ZM112.96 39.9227H118.611V19.179H112.96V39.9227Z" fill="${color}"/>
<path d="M122.384 39.9227H128.035V28.0222C128.035 25.3325 129.641 23.4748 132.176 23.4748C134.71 23.4748 135.988 25.0229 135.988 27.7319V39.9227H141.638V26.5516C141.638 21.6946 139.045 18.792 134.44 18.792C131.247 18.792 129.06 20.3014 127.957 22.875H127.841V19.179H122.384V39.9227Z" fill="${color}"/>
<path d="M151.081 27.4997H151.004V12H145.353V39.9227H151.004V33.0727L152.474 31.4859L158.415 39.9227H164.917L156.615 28.0802L164.53 19.179H158.124L151.081 27.4997Z" fill="${color}"/>
<path d="M178.733 31.0409V26.1065H166.019V31.0409H178.733Z" fill="${color}"/>
<path d="M201.837 26.8999C201.586 22.1977 198.025 18.734 192.414 18.734C186.202 18.734 182.184 22.4653 182.184 29.1218C182.184 35.8945 186.163 39.9227 192.414 39.9227C197.87 39.9227 201.528 36.7686 201.818 31.8729H196.555C196.206 34.1369 194.736 35.4915 192.472 35.4915C189.666 35.4915 187.907 32.7597 187.946 29.1218C187.946 25.1163 189.704 23.1652 192.491 23.1652C194.813 23.1652 196.264 24.7133 196.593 26.8999H201.837Z" fill="${color}"/>
<path d="M210.741 39.9227C213.334 39.9227 215.905 38.9552 217.066 36.7493H217.182V39.9227H222.639V25.6614C222.639 21.4817 219.175 18.734 213.854 18.734C208.358 18.734 204.933 21.5011 204.72 25.526H209.887C210.158 23.9973 211.493 22.9524 213.602 22.9524C215.731 22.9524 217.085 24.0747 217.085 26.0097V27.3836L211.861 27.6932C206.694 28.0028 203.792 30.1894 203.792 33.9628C203.792 37.6974 206.794 39.9227 210.741 39.9227ZM212.596 36.1687C210.68 36.1687 209.403 35.2012 209.403 33.6532C209.403 32.1825 210.603 31.2344 212.712 31.0796L217.085 30.8087V32.318C217.085 34.5627 215.054 36.1687 212.596 36.1687Z" fill="${color}"/>
<path d="M226.296 39.9227H231.947V28.3318C231.947 25.4099 233.572 23.707 236.358 23.707C237.171 23.707 237.945 23.8425 238.41 24.036V19.0629C238.023 18.9468 237.461 18.8501 236.804 18.8501C234.365 18.8501 232.605 20.282 231.869 22.9137H231.753V19.179H226.296V39.9227Z" fill="${color}"/>
<path d="M242.938 14.3995V19.179H240.016V23.4361H242.938V34.3304C242.938 38.3747 244.834 40.0001 249.71 40.0001C250.852 40.0001 251.82 39.9034 252.4 39.7679V35.6076C252.052 35.6656 251.529 35.7043 251.026 35.7043C249.362 35.7043 248.588 34.9497 248.588 33.3629V23.4361H252.419V19.179H248.588V14.3995H242.938Z" fill="${color}"/>
<path d="M25.0626 34.6098C25.3675 31.3178 28.1294 28.7998 31.4354 28.7998L60.5649 28.7998C63.4845 28.7998 64.8791 32.3892 62.7254 34.3604L27.7112 66.4072C25.553 68.3826 22.0946 66.6649 22.3643 63.7516L25.0626 34.6098Z" fill="#FF482A"/>
<g filter="url(#filter0_b)">
<path d="M43.7383 37.3902C43.4334 40.6822 40.6715 43.2002 37.3655 43.2002L8.23604 43.2002C5.3164 43.2002 3.92178 39.6108 6.07552 37.6396L41.0897 5.59277C43.2479 3.61742 46.7063 5.33508 46.4366 8.24837L43.7383 37.3902Z" fill="#FFD600" fill-opacity="0.85"/>
</g>
<defs>
<filter id="filter0_b" x="-0.684744" y="-0.969656" width="52.8505" height="49.8842" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImage" stdDeviation="2.85714"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"/>
</filter>
</defs>
</svg>
`
}

const Menu = () => {
  return (
    <ul className="menu">
      <li>
        <GatsbyLink to="/blink-frontend">Blink-Frontend</GatsbyLink>
      </li>
      <li>
        <GatsbyLink to="/blink-search">Blink-Search</GatsbyLink>
      </li>
      <li>
        <GatsbyLink to="#case-studies">Case Studies</GatsbyLink>
      </li>
      <li>
        <GatsbyLink to="/#faq">{langs.menu.faq}</GatsbyLink>
      </li>
    </ul>
  )
}
const Header: FC<HeaderProps> = ({ siteTitle = '', addBg = true }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleToggleTheme = () => {
    dispatch(toggleTheme())
  }

  if (!addBg) {
    return (
      <div className="top">
        <Link
          component={GatsbyLink}
          style={{ margin: '20px 0' }}
          to="/"
          className="logo"
        >
          <span dangerouslySetInnerHTML={{ __html: logoStr('white') }} />
        </Link>
        <Menu />
        {lan === 'pl' ? (
          <a className="lang" href="https://blink-cart.com/en/">
            Eng
          </a>
        ) : (
          <span></span>
        )}
      </div>
    )
  } else {
    return (
      <Box className={`section sectionHeaderPage`}>
        <Box className={`sectionInner`}>
          <div className="top">
            <Link
              component={GatsbyLink}
              style={{ margin: '20px 0' }}
              to="/"
              className="logo"
            >
              <span dangerouslySetInnerHTML={{ __html: logoStr('#5b5b5b') }} />
            </Link>
          </div>
        </Box>
      </Box>
    )
  }
  //<svg class="vL6BBg" viewBox="0 0 500 500"><path class="SQ2ADw _682gpw" d="M250 0a250 250 0 1 0 0 500 250 250 0 1 0 0-500z" fill="#ff5722" style="touch-action: manipulation;"></path></svg>
  // return (
  //   <AppBar component="header" position="static">
  //     <Toolbar className={classes.toolbar}>
  //       <Typography variant="h6" className={classes.title}>
  //         <Link
  //           to="/"
  //           component={GatsbyLink}
  //           color="inherit"
  //           className={classes.link}
  //         >
  //           {siteTitle}
  //         </Link>
  //       </Typography>
  //       <Button color="inherit" onClick={handleToggleTheme}>
  //         Toggle Theme
  //       </Button>
  //     </Toolbar>
  //   </AppBar>
  // )
}

export default Header
